import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import orderFragments from "../Queries/graphql/orderFragments";
import orderLinesAndEvents from "../Queries/graphql/orderLinesAndEvents";
import orderAllocationsFragments from "../Queries/graphql/orderAllocationsFragments";
import documentFragments from "../Queries/graphql/documentFragments";
import commentFragments from "../Queries/graphql/commentFragments";
import partnerFragments from "../Queries/graphql/partnerFragments";

class StreamlinedAssigner extends React.Component {
  render() {
    const { order, render } = this.props;
    return (
      <GraphqlWrapper>
        <Mutation mutation={SEND_TO_PARTNER}>
          {(update, { loading, error }) => {
            const sendToPartner = () => {
              update({
                variables: {
                  id: order.id
                }
              });
            };
            return render({ sendToPartner });
          }}
        </Mutation>
      </GraphqlWrapper>
    );
  }
}

StreamlinedAssigner.propTypes = {
  order: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired
};

export default StreamlinedAssigner;

const SEND_TO_PARTNER = gql`
  mutation streamlinedAssignment($id: ID!) {
    streamlinedAssignment(id: $id) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        orderAllocations {
          ...OrderAllocationsQuery
        }
        documents {
          ...DocumentQuery
        }
        comments {
          ...CommentQuery
        }
        partner {
          ...PartnerQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${orderAllocationsFragments.query}
  ${documentFragments.query}
  ${commentFragments.query}
  ${partnerFragments.query}
`;
