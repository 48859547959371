import React from "react";

import { ChecklistObject } from "../Queries/OrderQuery";
import ChecklistSection from "./ChecklistSection";

class TableView extends React.Component {
  render() {
    const { checklist } = this.props;

    return (
      <table className="table aidacare checklist">
        <tbody>
          {checklist.sections
            .toSorted(function(a, b) {
              if (a.number < b.number) {
                return -1;
              } else if (a.number > b.number) {
                return 1;
              } else {
                return 0;
              }
            })
            .map(section => (
              <ChecklistSection section={section} key={section.id} />
            ))}
        </tbody>
      </table>
    );
  }
}

TableView.propTypes = {
  checklist: ChecklistObject
};

export default TableView;
