import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";

import PartnerQuery from "./PartnerQuery";
import { OrderObject } from "../../Queries/OrderQuery";

class SelectPartner extends React.Component {
  render() {
    const { order, complete } = this.props;

    return (
      <PartnerQuery
        contractId={order.contract.id}
        render={({ partners }) => (
          <Downshift
            defaultIsOpen={true}
            onChange={(selection, stateAndHelpers) => complete(selection, stateAndHelpers)}
            itemToString={item => (item ? item.name : "")}
          >
            {({
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectedItem
            }) => (
              <div>
                <div className="search">
                  <input
                    {...getInputProps()}
                    className="search__input"
                    placeholder="Search Network Partners"
                    type="text"
                  />
                </div>

                <ul className="list--search list--scroll margin-bottom-base">
                  {isOpen
                    ? partners
                        .filter(p => this.showPartner(p, inputValue))
                        .map((p, index) => (
                          <li
                            key={p.id}
                            className="list__item"
                            {...getItemProps({
                              index,
                              item: p
                            })}
                          >
                            <div className="list__item__heading">{p.name}</div>
                            <div className="list__item__subheading">
                              {p.formattedAddress}
                            </div>
                          </li>
                        ))
                    : null}
                </ul>
              </div>
            )}
          </Downshift>
        )}
      />
    );
  }
  showPartner = (partner, inputValue) => {
    if (
      partner.isInternal &&
      (this.props.repairLinesSelected ||
        (this.props.homeModLinesSelected && this.props.order.complexHomeMods))
    ) {
      return false;
    } else if (!inputValue) {
      return true;
    } else {
      return (
        partner.name.toLowerCase() + partner.formattedAddress.toLowerCase()
      ).includes(inputValue.toLowerCase());
    }
  };
}

SelectPartner.propTypes = {
  order: OrderObject.isRequired,
  complete: PropTypes.func.isRequired,
  repairLinesSelected: PropTypes.bool.isRequired,
  homeModLinesSelected: PropTypes.bool.isRequired
};

export default SelectPartner;
