import PropTypes from "prop-types";
import { gql } from "@apollo/client";

const fragments = {
  query: gql`
    fragment PartnerQuery on Partner {
      id
      name
      isInternal
      isActive
      serviceDesc
      formattedAddress
      csMessage
      showRadaro
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    serviceDesc: PropTypes.string,
    formattedAddress: PropTypes.string,
    csMessage: PropTypes.string,
    showRadaro: PropTypes.bool
  })
};

export default fragments;
