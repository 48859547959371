import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import orderFragments from "./orderFragments";
import orderLinesAndEvents from "./orderLinesAndEvents";
import partnerFragments from "./partnerFragments";
import documentFragments from "./documentFragments";
import commentFragments from "./commentFragments";

const fragments = {
  query: gql`
    fragment OrderAllocationsQuery on Order {
      ...OrderFields
      uiState {
        pendingAllocationToInaccessiblePartner
      }
      ...OrderLinesAndEventsQuery
      partner {
        ...PartnerQuery
      }
      documents {
        ...DocumentQuery
      }
      documentCategoryOptions
      documentAllowedMimeTypes
      comments {
        ...CommentQuery
      }
      commentsVisibleToOptions
    }
    ${orderFragments.query}
    ${orderLinesAndEvents.query}
    ${partnerFragments.query}
    ${documentFragments.query}
    ${commentFragments.query}
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
};

export default fragments;
