import React from "react";

import { useParams } from "react-router";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import OrderQuery from "./Queries/OrderQuery";
import OrderUpdater from "./OrderUpdater";

import OrderList from "./List/index";
import OrderDisplay from "./OrderDisplay";

const OrdersApp = props => {
  return (
    <GraphqlWrapper>
      <Router>
        <Routes>
          <Route path="/" element={<OrderList />} />
          <Route path="/orders/:id" element={<Show />} />
        </Routes>
      </Router>
    </GraphqlWrapper>
  );
};

export default OrdersApp;

function Show() {
  let params = useParams();
  return (
    <OrderQuery
      orderId={params.id}
      render={({ order, currentUser, salesReps }) => (
        <OrderUpdater
          currentUser={currentUser}
          order={order}
          render={props => <OrderDisplay {...props} salesReps={salesReps} />}
        />
      )}
    />
  );
};
