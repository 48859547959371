import React from "react";
import { createRoot } from "react-dom/client";
import "url-search-params-polyfill";
import "@babel/polyfill";
import LocalTime from "local-time";

import ClientOrdersApp from "./../src/ClientOrders/index";
import ClientOrderItemsApp from "./../src/ClientOrderItems/index";
import ClientsApp from "./../src/Clients/index";
import ContractsApp from "./../src/Contracts/index";
import ContractNPUsersApp from "../src/Contracts/NPUsers";
import ContractHOUsersApp from "../src/Contracts/HOUsers";
import DashboardApp from "./../src/Dashboard/index";
import DebtorsApp from "../src/Debtors";
import FacilitiesApp from "../src/Facilities";
import FacilityOrdersApp from "../src/FacilityOrders";
import HealthcareProvidersApp from "./../src/HealthcareProviders/index";
import HealthcareProviderClientsApp from "./../src/HealthcareProviderClients/index";
import NetworkPartnersApp from "./../src/NetworkPartners/index";
import NetworkPartnerUsersApp from "./../src/NetworkPartnerUsers/index";
import OrdersApp from "./../src/Orders/index";
import ScheduleItemsApp from "./../src/ScheduleItem/index";
import UserAccountsApp from "./../src/UserAccounts/index";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { LicenseManager } from "ag-grid-enterprise";
import DischargeCostCentresApp from "../src/DischargeCostCentres";
import ContractUsersApp from "../src/UserAccounts/Contracts";
import ContractUserPartnersApp from "../src/UserAccounts/Contracts/NetworkPartners";
LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE_KEY);

let ErrorBoundary;
if (process.env.BUGSNAG_KEY) {
  var env = process.env.ENV_NAME ? process.env.ENV_NAME : "development";
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_KEY,
    releaseStage: env,
    plugins: [new BugsnagPluginReact()],
    onError: event => {
      event.addMetadata("Local Storage", window.localStorage);
    }
  });

  ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
} else {
  ErrorBoundary = ({ children }) => children;
}

LocalTime.start();

const AppWrapper = ({ Component, props }) => {
  return (
    <ErrorBoundary>
      <Component {...props || {}} />
    </ErrorBoundary>
  );
};

document.addEventListener("turbolinks:load", () => {
  const componentToIdMap = [
    { id: "client-orders", component: ClientOrdersApp },
    { id: "client-item-history", component: ClientOrderItemsApp },
    { id: "clients-list", component: ClientsApp },
    { id: "contracts", component: ContractsApp },
    { id: "dashboard", component: DashboardApp },
    { id: "facilities-list", component: FacilitiesApp },
    { id: "facility-orders", component: FacilityOrdersApp },
    { id: "healthcare-providers", component: HealthcareProvidersApp },
    {
      id: "healthcare-providers-clients",
      component: HealthcareProviderClientsApp
    },
    { id: "network-partners", component: NetworkPartnersApp },
    { id: "network_partner_users", component: NetworkPartnerUsersApp },
    { id: "contract-np-users", component: ContractNPUsersApp },
    { id: "contract-ho-users", component: ContractHOUsersApp },
    {
      id: "contracts-for-user",
      component: ContractUsersApp,
      props: { forItAdmin: false }
    },
    {
      id: "contracts-for-it-admin",
      component: ContractUsersApp,
      props: { forItAdmin: true }
    },
    { id: "contract-user-partners", component: ContractUserPartnersApp },

    { id: "embed-orders", component: OrdersApp },
    { id: "debtor-list", component: DebtorsApp },
    { id: "discharge-cost-centre-list", component: DischargeCostCentresApp },
    { id: "schedule-item-list", component: ScheduleItemsApp },
    { id: "user-accounts", component: UserAccountsApp }
  ];
  componentToIdMap.forEach(app => {
    const container = document.getElementById(app.id);
    if (container) {
      const root = createRoot(container);
      root.render(
        <AppWrapper Component={app.component} props={app.props || {}} />
      );
    }
  });
});

// TODO - give this a go, if this is not going to work well
// theres a conflict between react-router and turbolinks
// the other options are:
// 1) we remove turbolinks, which means changing all the turbolinks:load events
// 2) Switch away from react-router
// 3) try and find better options for getting them to work together
// whats below seems to work in my testing, but it does generate
// extra refreshes, when multiple back buttons are pressed
addEventListener("popstate", o => {
  if (window.history && !window.history.state) {
    // console.log("FORCED RELOAD!!!! *****************");
    // if so, force a page refresh, otherwise the display is not updated
    location.reload();
  }
});
