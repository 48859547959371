import React from "react";

import Modal from "Util/Modal";

import { OrderObject } from "../Queries/OrderQuery";
import PropTypes from "prop-types";
import SelectPartner from "../Lines/Assign/SelectPartner";
import OrderUpdater from "../OrderUpdater";

class PreferredPartnerBtn extends React.Component {
  state = { isOpen: false, orderAccessible: true };

  render() {
    const { order, partnerType, currentUser } = this.props;
    const partnerTypeString =
      partnerType === "install" ? "Install" : "Preferred";
    return (
      <React.Fragment>
        <button className="button button--white" onClick={this.open}>
          Update {partnerTypeString} Partner
        </button>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={this.close}
            header={`${partnerTypeString} Partner`}
          >
            <OrderUpdater
              currentUser={currentUser}
              order={order}
              onComplete={this.close}
              render={({ updateOrder }) => (
                <SelectPartner
                  order={order}
                  complete={(selection, stateAndHelpers) =>
                    this.partnerSelected(selection, stateAndHelpers, updateOrder, partnerType)
                  }
                  repairLinesSelected={false}
                  homeModLinesSelected={false}
                />
              )}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  open = () => this.setState({ isOpen: true });

  close = () => {
    if (this.state.orderAccessible) {
      this.setState({ isOpen: false });
    } else {
      window.location.href = "/";
    }
  };

  partnerSelected = (selection, stateAndHelpers, updateOrder, partnerType) => {
    if (selection === null) return;

    const order = { id: this.props.order.id };
    if (partnerType === "install") {
      order["installPartnerId"] = selection.id;
    } else {
      order["partnerId"] = selection.id;
    }
    if (
      this.props.order.uiState.currentUserIsWholesale &&
      !selection.userIsAssigned
    ) {
      if (
        window.confirm(
          "Order will be allocated to the selected branch but you do not have access.\n" +
            "Please confirm you would like to change the branch and be redirected the orders listing page."
        )
      ) {
        this.setState({ orderAccessible: false });
        updateOrder(order);
      } else {
        stateAndHelpers.clearSelection();
      }
    } else {
      updateOrder(order);
    }
  };
}

PreferredPartnerBtn.propTypes = {
  order: OrderObject,
  partnerType: PropTypes.string,
  currentUser: PropTypes.object.isRequired
};

export default PreferredPartnerBtn;
