import React from "react";
import PropTypes from "prop-types";

import { OrderObject, CurrentUserObject } from "./Queries/OrderQuery";

import OrderContext from "./OrderContext";
import TitleBar from "./TitleBar";
import OrderStepBar from "./OrderStepBar";
import RelatedOrderTabs from "./RelatedOrderTabs";
import OrderDetails from "./OrderDetails";
import PreValidationSteps from "./PreValidationSteps";
import PartnerApproval from "./PartnerApproval";

class OrdersDisplay extends React.Component {
  state = { uiActive: true };

  render() {
    const { order, currentUser, salesReps, updateOrder } = this.props;

    return (
      <OrderContext.Provider>
        <div className={"main_section"}>
          <div className={"main_section__content"}>
            {order.partner &&
              order.partner.csMessage &&
              order.uiState.currentUserIsHeadOffice && (
                <h3 className="red">
                  <strong>{order.partner.csMessage}</strong>
                </h3>
              )}
            <TitleBar
              order={order}
              updateOrder={updateOrder}
              currentUser={currentUser}
              uiActive={this.state.uiActive}
              disableUIFunc={this.disableUIFunc}
            />
            {order.uiState.showStep1 && (
              <OrderStepBar heading="Step 1 - Validate client and healthcare provider details" />
            )}
            <OrderDetails
              order={order}
              updateOrder={updateOrder}
              currentUser={currentUser}
              salesReps={salesReps}
              disableUIFunc={this.disableUIFunc}
            />
            {order.uiState.showStep2 && (
              <OrderStepBar heading="Step 2 - Check item eligibility" />
            )}
            <RelatedOrderTabs
              order={order}
              key={order.id}
              currentUser={currentUser}
              uiActive={this.state.uiActive}
              disableUIFunc={this.disableUIFunc}
            />
            {order.uiState.showStep2 && (
              <PreValidationSteps
                order={order}
                updateOrder={updateOrder}
                currentUser={currentUser}
              />
            )}
            {order.uiState.showPartnerApproval && (
              <PartnerApproval order={order} updateOrder={updateOrder} />
            )}
          </div>
        </div>
      </OrderContext.Provider>
    );
  }

  disableUIFunc = disabled => {
    this.setState({ uiActive: !disabled });
  };
}

OrdersDisplay.propTypes = {
  order: OrderObject,
  currentUser: CurrentUserObject.isRequired,
  salesReps: PropTypes.array.isRequired,
  updateOrder: PropTypes.func.isRequired
};

export default OrdersDisplay;
