import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

import GraphqlWrapper from "GraphQL/GraphqlWrapper";

import orderFragments from "./../../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../../Queries/graphql/orderLinesAndEvents";
import orderAllocationsFragments from "./../../Queries/graphql/orderAllocationsFragments";
import documentFragments from "./../../Queries/graphql/documentFragments";
import commentFragments from "./../../Queries/graphql/commentFragments";
import partnerFragments from "./../../Queries/graphql/partnerFragments";

class PartnerSender extends React.Component {
  render() {
    const { order, render } = this.props;
    return (
      <GraphqlWrapper>
        <Mutation mutation={SEND_TO_PARTNER} onCompleted={this.onCompleted}>
          {(update, { loading, error }) => {
            const sendToPartner = () => {
              let confirmMessage = "Send to partner?";
              if (order.uiState.pendingAllocationToInaccessiblePartner) {
                confirmMessage =
                  "Order will be sent to the branch but you will no longer have access.\n" +
                  "Please confirm you would like to assign the order and be redirected the orders listing page.";
              }
              if (window.confirm(confirmMessage)) {
                update({
                  variables: {
                    id: order.id
                  }
                });
              }
            };
            return render({ order, sendToPartner });
          }}
        </Mutation>
      </GraphqlWrapper>
    );
  }
  onCompleted = data => {
    if (this.props.order.uiState.pendingAllocationToInaccessiblePartner) {
      window.location.href = "/";
    }
  };
}

PartnerSender.propTypes = {
  order: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired
};

export default PartnerSender;

const SEND_TO_PARTNER = gql`
  mutation sendOrderToPartner($id: ID!) {
    sendOrderToPartner(id: $id) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        orderAllocations {
          ...OrderAllocationsQuery
        }
        documents {
          ...DocumentQuery
        }
        comments {
          ...CommentQuery
        }
        partner {
          ...PartnerQuery
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${orderAllocationsFragments.query}
  ${documentFragments.query}
  ${commentFragments.query}
  ${partnerFragments.query}
`;
