import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

import ClinicalJustification from "./ClinicalJustification";
import SeekApproval from "./SeekApproval";
import AssignOrderBtn from "./AssignOrderBtn";
import OrderValidationMessage from "./../OrderValidationMessage";
import { isOnDvaContract } from "./../BusRules/access";
import StreamlinedAssignmentBtn from "../StreamlinedAssignment";
import PreferredPartnerBtn from "../PreferredPartnerBtn";

const PreValidationStepsIndex = props => {
  if (props.order == null) {
    return <div>loading...</div>;
  }
  if (props.order.uiState.processStep2Message) {
    return (
      <OrderValidationMessage>
        {props.order.uiState.processStep2Message}
      </OrderValidationMessage>
    );
  }
  if (isOnDvaContract(props.order)) {
    return (
      <section>
        <ClinicalJustification {...props} />
        <SeekApproval {...props} approval="seekPriorApproval"/>
        <AssignOrderBtn {...props} />
      </section>
    );
  } else if (props.order.uiState.showStreamlinedAssignment) {
    return (
      <section className="title_bar__actions">
        <PreferredPartnerBtn
          order={props.order}
          currentUser={props.currentUser}
        />
        <StreamlinedAssignmentBtn order={props.order} />
      </section>
    );
  } else {
    return (
      <section>
        <AssignOrderBtn {...props} />
      </section>
    );
  }
};

PreValidationStepsIndex.propTypes = {
  order: OrderObject,
  updateOrder: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default PreValidationStepsIndex;
