import React from "react";

import checklistSectionFragments from "../Queries/graphql/checklistSectionFragments";
import ChecklistItem from "./ChecklistItem";

class ChecklistSection extends React.Component {
  render() {
    const { section } = this.props;

    return (
      <React.Fragment>
        <tr className="table__row checklist__header">
          <td className="table__cell">{section.number}</td>
          <td className="table__cell">{section.header}</td>
          <td className="table__cell" />
        </tr>
        {section.items
          .toSorted(function(a, b) {
            if (a.number < b.number) {
              return -1;
            } else if (a.number > b.number) {
              return 1;
            } else {
              return 0;
            }
          })
          .map(item => (
            <ChecklistItem item={item} key={item.id} />
          ))}
        {section.footer && (
          <tr className="table__row checklist__footer">
            <td className="table__cell" />
            <td className="table__cell">{section.footer}</td>
            <td className="table__cell" />
          </tr>
        )}
      </React.Fragment>
    );
  }
}

ChecklistSection.propTypes = {
  section: checklistSectionFragments.object.isRequired
};

export default ChecklistSection;
